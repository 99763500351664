import { DepartmentTag } from "@/components/Shared/DepartmentTag/DepartmentTag";
import { IrisSpin } from "@/components/Shared/IrisSpin/IrisSpin";
import { useRelationshipSelectOptionsData } from "@/lib/shared/hooks/form/useRelationshipSelectOptionsData";
import { ProFormSelect } from "@ant-design/pro-form";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { Empty, FormInstance } from "antd";
import React, { useState } from "react";
import { useDepartmentCollectionQuery } from "../../hooks/useDepartmentCollectionQuery.hook";
import { useSingleDepartmentQuery } from "../../hooks/useSingleDepartmentQuery.hook";

export interface SingleDepartmentPickerProps extends ProFormSelectProps {
  className?: string;
  form?: FormInstance;
  fetchSingleId?: number;
}

export const SingleDepartmentPicker = (props: SingleDepartmentPickerProps) => {
  const { form, fetchSingleId } = props;
  const [hasBeenActivated, setHasBeenActivated] = useState(false);

  const { data: initialValueData, isFetching: initialFetching } =
    useSingleDepartmentQuery(parseInt(`${fetchSingleId}`), {
      enabled: !!fetchSingleId,
    });

  const { data, setFilters, isFetching } = useDepartmentCollectionQuery(
    { pageSize: 20 },
    {
      enabled: hasBeenActivated,
    }
  );

  const departments = useRelationshipSelectOptionsData(
    data?.departments,
    initialValueData
  );

  return (
    <>
      <ProFormSelect
        showSearch
        fieldProps={{
          optionItemRender: (value) => (
            <div>
              <DepartmentTag departmentName={value.label} />
            </div>
          ),
          onMouseEnter: () => setHasBeenActivated(true),
          onSearch: (keyword) => {
            setFilters({
              name_contains: keyword,
            });
          },
          loading: isFetching || initialFetching,
          dropdownRender: (menu) => {
            if (isFetching) {
              return (
                <div className="flex justify-center h-12 items-center">
                  <IrisSpin />
                </div>
              );
            }
            return (
              <div>
                {!!data && data?.departments.length > 0 ? (
                  <>{menu}</>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
            );
          },
        }}
        options={departments.map((department) => ({
          label: department.name,
          value: department.id,
        }))}
        {...props}
      />
    </>
  );
};
