import { CompanyPartnerSelect } from "@/lib/features/company-partners/components/CompanyPartnerSelect/CompanyPartnerSelect";
import { SingleDepartmentPicker } from "@/lib/features/departments/components/SingleDepartmentPicker/SingleDepartmentPicker";
import { SingleUserSelect } from "@/lib/features/users/components/form/selects/SingleUserSelect/SingleUserSelectDynamic";
import {
  FormInstance,
  ProFormDependency,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-form";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ProjectFormFieldsProps {
  className?: string;
  form: FormInstance;
  fieldsAreReadOnly?: boolean;
}

export const ProjectFormFields = (props: ProjectFormFieldsProps) => {
  const { className = "", fieldsAreReadOnly = false, form } = props;
  const { t } = useTranslation(["project", "common"]);

  return (
    <>
      {/* Name */}
      <ProFormText
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        label={t("attributes.name.label")}
        placeholder={t("attributes.name.placeholder")}
        name="name"
        readonly={fieldsAreReadOnly}
      />

      {/* DEPARTMENT */}
      <ProFormDependency shouldUpdate name={["department"]}>
        {({ department }) => (
          <SingleDepartmentPicker
            rules={[
              {
                required: true,
                message: t("common:form.errors.required"),
              },
            ]}
            mode="single"
            fetchSingleId={department}
            form={form}
            label={t("attributes.department.label")}
            name="department"
            readonly={fieldsAreReadOnly}
          />
        )}
      </ProFormDependency>

      {/* PARTNER */}
      <CompanyPartnerSelect
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        name="partner"
        form={form}
        readonly={fieldsAreReadOnly}
        label={t("attributes.partner.label")}
      />

      {/* MAIN MANAGER */}
      <SingleUserSelect
        name="mainManager"
        form={form}
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        readonly={fieldsAreReadOnly}
        label={t("attributes.mainManager.label")}
      />

      <ProFormText
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        label={t("attributes.invoiceMaxSumAllowedForProjectManager.label")}
        placeholder={t(
          "attributes.invoiceMaxSumAllowedForProjectManager.placeholder"
        )}
        name="invoiceMaxSumAllowedForProjectManager"
        disabled={fieldsAreReadOnly}
        fieldProps={{
          type: "number",
          addonAfter: "€",
        }}
      />

      {/* MANAGER */}
      <SingleUserSelect
        mode="multiple"
        name="managers"
        form={form}
        rules={[
          {
            required: true,
            message: t("common:form.errors.required"),
          },
        ]}
        readonly={fieldsAreReadOnly}
        label={t("attributes.managers.label")}
      />

      {/* DESCRIPTION */}
      <ProFormTextArea
        rules={[]}
        label={t("attributes.description.label")}
        placeholder={t("attributes.description.placeholder")}
        name="description"
        readonly={fieldsAreReadOnly}
      />
    </>
  );
};
