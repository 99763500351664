import { PageContainer, PageContainerProps } from "@ant-design/pro-layout";
import React, { ReactNode } from "react";

export interface PageContentProps extends PageContainerProps {
  className?: string;
  children: ReactNode[] | ReactNode;
}

export const PageContent = (props: PageContentProps) => {
  const { className = "", children } = props;
  return (
    <PageContainer fixedHeader className={`md:m-6 m-2 ${className}`} {...props}>
      {children}
    </PageContainer>
  );
};
