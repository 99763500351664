import { gql } from "graphql-tag";
import { departmentFragment } from "./department.fragment";

// Mutation to create department
export const CreateDepartmentMutation = gql`
	${departmentFragment}
	mutation createDepartmentMutation($input: createDepartmentInput) {
		createDepartment(input: $input) {
			department {
				...departmentFragment
			}
		}
	}
`;

// Mutation to update department
export const UpdateDepartmentMutation = gql`
	${departmentFragment}
	mutation updateDepartmentMutation($input: updateDepartmentInput) {
		updateDepartment(input: $input) {
			department {
				...departmentFragment
			}
		}
	}
`;

// Mutation to delete department
export const DeleteDepartmentMutation = gql`
	${departmentFragment}
	mutation deleteDepartmentMutation($input: deleteDepartmentInput) {
		deleteDepartment(input: $input) {
			department {
				...departmentFragment
			}
		}
	}
`;
