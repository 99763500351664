import { usePagination } from "@/lib/features/shared/hooks/usePagination";
import { Pagination } from "@/lib/features/shared/model/pagination";
import { antToStrapiSorterMap } from "@/lib/features/shared/utils/antToStrapiSorterMap.util";
import { transformTableFilters } from "@/lib/features/shared/utils/transformTableFilters.util";
import { queryClient } from "@/lib/react-query/client";
import { useCallback, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { getCompanyPartnerCollection } from "../api/company-partner.api";
import { USE_COMPANY_PARTNER_COLLECTION_QUERY_KEY } from "../consts/company-partner.query-keys";
import { GetCompanyPartnerCollectionReturn } from "./../model/actions/company-partner-read.model";

export const prefetchCompanyPartnerCollectionQuery = async (
  paginationDefaults: Pagination = {}
) => {
  await queryClient.prefetchQuery(
    [
      USE_COMPANY_PARTNER_COLLECTION_QUERY_KEY,
      { current: 1, pageSize: 20, showSizeChanger: true },
      {},
      "id:desc",
    ],
    () =>
      getCompanyPartnerCollection({
        limit: 20,
        start: 1,
        sort: "id:desc",
        where: {},
      })
  );
};
export const useCompanyPartnerCollectionQuery = (
  paginationDefaults: Pagination = { pageSize: 20 },
  queryOptions: UseQueryOptions<GetCompanyPartnerCollectionReturn> = {}
) => {
  const { pagination, setPagination } = usePagination(paginationDefaults);
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState("id:desc");

  const queryResult = useQuery(
    [USE_COMPANY_PARTNER_COLLECTION_QUERY_KEY, pagination, filters, sorter],
    () =>
      getCompanyPartnerCollection({
        limit: pagination.pageSize,
        sort: sorter,
        start:
          pagination.current === 1
            ? 0
            : (pagination.current - 1) * pagination.pageSize,
        where: filters,
      }),
    {
      keepPreviousData: true,
      staleTime: 10000,
      ...queryOptions,
    }
  );

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    setPagination(pagination);
    if (sorter?.order) {
      setSorter(`${sorter.columnKey}:${antToStrapiSorterMap[sorter.order]}`);
    }
    setFilters(transformTableFilters(filters));
  }, []);

  return {
    ...queryResult,
    handleTableChange,
    pagination,
    setFilters,
  };
};
