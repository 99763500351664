import { usePagination } from "@/lib/features/shared/hooks/usePagination";
import { Pagination } from "@/lib/features/shared/model/pagination";
import { antToStrapiSorterMap } from "@/lib/features/shared/utils/antToStrapiSorterMap.util";
import { transformTableFilters } from "@/lib/features/shared/utils/transformTableFilters.util";
import { queryClient } from "@/lib/react-query/client";
import { useCallback, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { getDepartmentCollection } from "../api/department.api";
import { USE_DEPARTMENT_COLLECTION_QUERY_KEY } from "../consts/department.query-keys";
import { GetDepartmentCollectionReturn } from "./../model/actions/department-read.model";

export const prefetchDepartmentCollectionQuery = async (
  paginationDefaults: Pagination = {}
) => {
  await queryClient.prefetchQuery(
    [
      USE_DEPARTMENT_COLLECTION_QUERY_KEY,
      { current: 0, pageSize: 10, total: null, showSizeChanger: true },
      {},
    ],
    () =>
      getDepartmentCollection({
        limit: 10,
        start: 0,
      })
  );
};
export const useDepartmentCollectionQuery = (
  paginationDefaults: Pagination = {},
  queryOptions: UseQueryOptions<GetDepartmentCollectionReturn> = {}
) => {
  const { pagination, setPagination } = usePagination(paginationDefaults);
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState("id:desc");

  const queryResult = useQuery(
    [USE_DEPARTMENT_COLLECTION_QUERY_KEY, pagination, filters, sorter],
    () =>
      getDepartmentCollection({
        limit: pagination.pageSize,
        sort: sorter,
        start:
          pagination.current === 1
            ? 0
            : (pagination.current - 1) * pagination.pageSize,
        where: filters,
      }),
    {
      keepPreviousData: true,
      ...queryOptions,
    }
  );

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    setPagination(pagination);
    if (sorter?.order) {
      setSorter(`${sorter.columnKey}:${antToStrapiSorterMap[sorter.order]}`);
    }
    setFilters(transformTableFilters(filters));
  }, []);

  return {
    ...queryResult,
    handleTableChange,
    pagination,
    setFilters,
  };
};
