import { gql } from "graphql-tag";

export const departmentFragment = gql`
  fragment departmentFragment on Department {
    id
    name
    maxInvoiceSumAllowedForDepartmentManager
    employees {
      id
      name
    }
    administrators {
      id
      name
    }
    head {
      id
      name
    }
    financialDecisionMaker {
      id
      name
    }
    head_assistant {
      id
      name
    }
  }
`;
