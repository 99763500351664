import { gql } from "graphql-tag";
import { departmentFragment } from "./department.fragment";

// Query to retrieve single department
export const GET_SINGLE_DEPARTMENT_QUERY = gql`
	${departmentFragment}
	query getSingleDepartmentQuery($id: ID!) {
		department(id: $id) {
			...departmentFragment
		}
	}
`;

// Query to retrieve multiple departments
export const GET_DEPARTMENTS_QUERY = gql`
	${departmentFragment}
	query getDepartmentsQuery(
		$sort: String = "id:desc"
		$limit: Int
		$start: Int
		$where: JSON
	) {
		departments(sort: $sort, limit: $limit, start: $start, where: $where) {
			...departmentFragment
		}

		departmentsCount(where: $where)
	}
`;
