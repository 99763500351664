import { queryClient } from "@/lib/react-query/client";
import { useQuery, UseQueryOptions } from "react-query";
import { getSingleDepartment } from "../api/department.api";
import { USE_SINGLE_DEPARTMENT_QUERY_KEY } from "../consts/department.query-keys";
import { Department } from "../model/department.model";

export const prefetchSingleDepartmentQuery = async (id: number) => {
  await queryClient.prefetchQuery(
    [
      USE_SINGLE_DEPARTMENT_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleDepartment(id)
  );
};
export const useSingleDepartmentQuery = (
  id: number,
  options: UseQueryOptions<Department>
) => {
  return useQuery<Department>(
    [
      USE_SINGLE_DEPARTMENT_QUERY_KEY,
      { id: typeof id === "number" ? id : parseInt(id) },
    ],
    () => getSingleDepartment(id),
    {
      staleTime: 8000,
      enabled: !!id,
      ...options,
    }
  );
};
