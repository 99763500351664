import { useMemo } from "react";

interface HasId {
  id: number | string;
}

/**
 *  Initializes an array which is always going to have the current select value loaded
 */
export const useRelationshipSelectOptionsData = <T extends HasId>(
  data: T[],
  initialValueData: T | null
): T[] => {
  return useMemo(() => {
    const optionData = [];

    if (data) {
      data.forEach((option) => optionData.push(option));
    }

    if (
      !!initialValueData &&
      (data?.findIndex((option) => option.id === initialValueData.id) === -1 ||
        optionData.length === 0)
    ) {
      optionData.push(initialValueData);
    }

    return optionData;
  }, [initialValueData, data]);
};

/**
 *  Initializes an array which is always going to have the current select value loaded
 */
export const useRelationshipSelectOptionsDataWithMultiple = <T extends HasId>(
  data: T[],
  initialValueDataMultiple: T[] = []
): T[] => {
  return useMemo(() => {
    const optionData = [];

    if (data) {
      data.forEach((option) => optionData.push(option));
    }

    for (const initialValueData of initialValueDataMultiple) {
      if (
        !!initialValueData &&
        (data?.findIndex((option) => option.id === initialValueData.id) ===
          -1 ||
          optionData.length === 0)
      ) {
        optionData.push(initialValueData);
      }
    }

    return optionData;
  }, [initialValueDataMultiple, data]);
};
