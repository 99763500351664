import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

export interface IrisSpinProps {
  className?: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const IrisSpin = (props: IrisSpinProps) => {
  const { className = "" } = props;
  return <Spin indicator={antIcon} {...props} />;
};
