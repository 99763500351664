import { Pagination } from "@/lib/features/shared/model/pagination";
import { useState } from "react";

export let usePagination = (defaults: Pagination) => {
  // const [pagination, setPagination] = useQueryState("pagination", {
  //   defaultValue: {
  //     current: 1,
  //     pageSize: 20,
  //     showSizeChanger: true,
  //     ...defaults,
  //   },
  //   serialize: (value) => {
  //     // JSON.stringify(value);
  //     console.log("serializing ");
  //     return JSON.stringify(value);
  //   },
  //   parse: (value) => JSON.parse(value),
  // });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    ...defaults,
  });

  return { pagination, setPagination };
};
