import { Tag } from "antd";
import React from "react";
import stc from "string-to-color";

export interface DepartmentTagProps {
  className?: string;
  departmentName: string;
  color?: string;
}

export const DepartmentTag = (props: DepartmentTagProps) => {
  const { className = "", departmentName, color } = props;
  return <Tag  color={stc(departmentName)}>{departmentName}</Tag>;
};
