import { graphqlApi } from "@/lib/api/graphqlApi";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import {
  CreateDepartmentMutation,
  DeleteDepartmentMutation,
  UpdateDepartmentMutation,
} from "../graphql/department.mutations";
import {
  GET_DEPARTMENTS_QUERY,
  GET_SINGLE_DEPARTMENT_QUERY,
} from "../graphql/department.queries";
import {
  CreateDepartmentInput,
  CreateDepartmentReturn,
} from "../model/actions/department-create.model";
import {
  DeleteDepartmentInput,
  DeleteDepartmentReturn,
} from "../model/actions/department-delete.model";
import {
  GetDepartmentCollectionReturn,
  GetSingleDepartmentReturn,
} from "../model/actions/department-read.model";
import {
  UpdateDepartmentInput,
  UpdateDepartmentReturn,
} from "../model/actions/department-update.model";
import { Department } from "../model/department.model";

/** Api method to create a department */
export const createDepartment = async (
  payload: CreateDepartmentInput
): Promise<Department> => {
  const {
    data: {
      createDepartment: { department },
    },
  } = await graphqlApi<CreateDepartmentReturn>(CreateDepartmentMutation, {
    variables: {
      input: payload,
    },
  });

  return department;
};

/** Api method to fetch a single department */
export const getSingleDepartment = async (id: number): Promise<Department> => {
  const {
    data: { department },
  } = await graphqlApi<GetSingleDepartmentReturn>(GET_SINGLE_DEPARTMENT_QUERY, {
    variables: {
      id,
    },
  });

  return department;
};

/** Api method to fetch multiple departments in a collection with all the filters specified in queryInput applied */
export const getDepartmentCollection = async (
  queryInput: CollectionQueryInput
) => {
  const { data } = await graphqlApi<GetDepartmentCollectionReturn>(
    GET_DEPARTMENTS_QUERY,
    {
      variables: queryInput,
    }
  );

  return data;
};

/** Api method to update department */
export const updateDepartment = async (
  updatePayload: UpdateDepartmentInput
): Promise<Department> => {
  const {
    data: {
      updateDepartment: { department },
    },
  } = await graphqlApi<UpdateDepartmentReturn>(UpdateDepartmentMutation, {
    variables: {
      input: updatePayload,
    },
  });

  return department;
};

/** Api method to delete department */
export const deleteDepartment = async (
  deleteInput: DeleteDepartmentInput
): Promise<Department> => {
  const {
    data: {
      deleteDepartment: { department },
    },
  } = await graphqlApi<DeleteDepartmentReturn>(DeleteDepartmentMutation, {
    variables: {
      input: deleteInput,
    },
  });

  return department;
};
