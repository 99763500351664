import { ModalForm, ModalFormProps, ProFormText } from "@ant-design/pro-form";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateCompanyPartnerInputData } from "../../model/actions/company-partner-create.model";

export interface CompanyPartnerModalFormProps extends ModalFormProps {
  className?: string;
  fieldsAreReadOnly?: boolean;
}

export const CompanyPartnerModalForm = (
  props: CompanyPartnerModalFormProps
) => {
  const { className = "", fieldsAreReadOnly = false } = props;
  const { t } = useTranslation(["company-partner", "common"]);
  return (
    <ModalForm<CreateCompanyPartnerInputData> width={700} {...props}>
      <Row gutter={32}>
        <Col xs={24}>
          {/* NAME */}
          <ProFormText
            rules={[
              {
                required: true,
                message: t("common:form.errors.required"),
              },
            ]}
            label={t("attributes.name.label")}
            placeholder={t("attributes.name.placeholder")}
            name="name"
            readonly={fieldsAreReadOnly}
          />
        </Col>
        <Col xs={24} md={12}>
          {/* STREET */}
          {/* businessId */}
          <ProFormText
            rules={[
              {
                required: true,
                message: t("common:form.errors.required"),
              },
            ]}
            label={t("attributes.businessId.label")}
            placeholder={t("attributes.businessId.placeholder")}
            name="businessId"
            readonly={fieldsAreReadOnly}
          />

          {/* TAXID */}
          <ProFormText
            rules={[
              {
                required: true,
                message: t("common:form.errors.required"),
              },
            ]}
            label={t("attributes.taxId.label")}
            placeholder={t("attributes.taxId.placeholder")}
            name="taxId"
            readonly={fieldsAreReadOnly}
          />

          {/* VATID */}
          <ProFormText
            rules={[]}
            label={t("attributes.vatId.label")}
            placeholder={t("attributes.vatId.placeholder")}
            name="vatId"
            readonly={fieldsAreReadOnly}
          />
        </Col>
        <Col xs={24} md={12}>
          {/* PHONE */}
          {/* IBAN */}
          <ProFormText
            rules={[]}
            label={t("attributes.iban.label")}
            placeholder={t("attributes.iban.placeholder")}
            name="iban"
            readonly={fieldsAreReadOnly}
          />

          {/* BANKCODE */}
          <ProFormText
            rules={[]}
            label={t("attributes.bankCode.label")}
            placeholder={t("attributes.bankCode.placeholder")}
            name="bankCode"
            readonly={fieldsAreReadOnly}
          />
        </Col>
      </Row>
    </ModalForm>
  );
};
