import { IrisSpin } from "@/components/Shared/IrisSpin/IrisSpin";
import { AbilityContext } from "@/components/Shared/utils/CaslProvider/CaslProvider";
import { useRelationshipSelectOptionsData } from "@/lib/shared/hooks/form/useRelationshipSelectOptionsData";
import { PlusOutlined } from "@ant-design/icons";
import { FormInstance, ProFormSelect } from "@ant-design/pro-form";
import { ProFormSelectProps } from "@ant-design/pro-form/lib/components/Select";
import { useAbility } from "@casl/react";
import { Button, Empty } from "antd";
import debounce from "lodash.debounce";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyPartnerCollectionQuery } from "../../hooks/useCompanyPartnerCollectionQuery.hook";
import { useSingleCompanyPartnerQuery } from "../../hooks/useSingleCompanyPartnerQuery.hook";
import { AddCompanyPartnerModalForm } from "../AddCompanyPartnerModalForm/AddCompanyPartnerModalForm";

export interface CompanyPartnerSelectProps extends ProFormSelectProps {
  className?: string;
  form?: FormInstance;
}

export const CompanyPartnerSelect = (props: CompanyPartnerSelectProps) => {
  const { className = "", form } = props;
  const [hasBeenActivated, setHasBeenActivated] = useState(false);
  const ability = useAbility(AbilityContext);

  const { data, isFetching, isLoading, setFilters } =
    useCompanyPartnerCollectionQuery(
      {
        pageSize: 20,
      },
      {
        enabled: hasBeenActivated,
      }
    );

  const { data: initialValueData } = useSingleCompanyPartnerQuery(
    form?.getFieldValue(props.name),
    {}
  );

  const partners = useRelationshipSelectOptionsData(
    data?.companyPartners,
    initialValueData
  );

  const { t } = useTranslation(["common"]);

  return (
    <>
      <ProFormSelect
        {...props}
        showSearch
        fieldProps={{
          onMouseEnter: () => setHasBeenActivated(true),
          onSearch: debounce((keyword) => {
            setFilters({
              name_contains: keyword,
            });
          }, 300),
          loading: isFetching || isLoading,
          dropdownRender: (menu) => {
            return (
              <div>
                {!!data && data?.companyPartners.length > 0 ? (
                  <>{menu}</>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {isFetching && (
                  <div className="flex justify-center h-12 items-center">
                    <IrisSpin />
                  </div>
                )}
                <AddCompanyPartnerModalForm
                  modalProps={{
                    zIndex: 2000,
                  }}
                  onPartnerCreated={({ id }) => {
                    form.setFieldsValue({ [props.name as string]: id });
                  }}
                  submitter={{
                    searchConfig: {
                      submitText: t("common:actions.add"),
                      resetText: t("common:actions.cancel"),
                    },
                  }}
                  trigger={
                    <Button
                      icon={<PlusOutlined />}
                      type="link"
                      hidden={ability.cannot("create", "company-partner")}
                      className="flex w-full text-left justify-start"
                    >
                      Pridať partnera
                    </Button>
                  }
                />
              </div>
            );
          },
        }}
        options={partners.map((partner) => ({
          label: partner?.name,
          value: partner.id,
        }))}
      />
    </>
  );
};
