import { queryClient } from "@/lib/react-query/client";
import { ModalFormProps } from "@ant-design/pro-form";
import { Form, message } from "antd";
import { createCompanyPartner } from "../../api/company-partner.api";
import { USE_COMPANY_PARTNER_COLLECTION_QUERY_KEY } from "../../consts/company-partner.query-keys";
import { CompanyPartner } from "../../model/company-partner.model";
import { CompanyPartnerModalForm } from "../CompanyPartnerModalForm/CompanyPartnerModalForm";

export interface AddCompanyPartnerModalFormProps extends ModalFormProps {
  className?: string;
  onPartnerCreated?: (partner: CompanyPartner) => void;
}

export const AddCompanyPartnerModalForm = (
  props: AddCompanyPartnerModalFormProps
) => {
  const { className = "", onPartnerCreated } = props;
  const [form] = Form.useForm();

  return (
    <CompanyPartnerModalForm
      title="Pridať partnera"
      form={form}
      onFinish={async (values) => {
        // Create the partner
        const partner = await createCompanyPartner({
          data: values,
        });

        // Reset the form for the next address
        message.info("Partner úspešne pridaný");

        queryClient.invalidateQueries(USE_COMPANY_PARTNER_COLLECTION_QUERY_KEY);

        if (onPartnerCreated) {
          onPartnerCreated(partner);
        }

        return true;
      }}
      {...props}
    />
  );
};
